<template>
  <div class="page" :class="isMobile && 'mobile'">
    <van-row class="nav" v-show="isShowNav">
      <van-col class="nav-item" v-if="images.logo">
        <van-image class="logo" :src="images.logo" />
      </van-col>
      <van-col
        class="nav-item"
        :class="{ 'nav-item-active': menu === i }"
        v-for="(item, i) in menuList"
        :key="i"
        @click="onMenuClick(i)"
      >
        {{ item }}
      </van-col>
    </van-row>

    <van-swipe
      v-if="menu == 0"
      class="box"
      ref="vSwipe"
      @change="onChangeSwipe"
      :loop="false"
      :showIndicators="false"
      :lazyRender="true"
      :vertical="true"
      :touchable="true"
    >
      <van-swipe-item class="box-item box1">
        <template v-if="swipeIndex == 0">
          <div v-if="isMobile" class="m-menu">
            <van-image
              width="100%"
              height="100%"
              :src="images.m_menu"
              @click="m_menu_visible = !m_menu_visible"
            />
            <!-- <div v-show="m_menu_visible" class="m-menu-item" @click="toPay()">充值</div> -->
          </div>

          <van-image class="box-item-bg" :src="images.bg[0]" />
          <div class="box-item-content">
            <!-- <div class="box1-iphone">
              <van-image class="box1-iphone-item" :src="images.iphone[0]" />
              <van-image class="box1-iphone-item" :src="images.iphone[1]" />
            </div> -->
            <van-image class="box1-font" :src="images.font[0]" />
            <van-image class="box1-girl" :src="images.girl[0]" />
            <!-- <van-image class="box1-down" :src="images.down" @click="onDownLoad()" /> -->
            <van-image  class="box-slide" :src="images.slide_moible[0]" />
            <van-image  class="box-slide" :src="images.slide_pc[0]" />
          </div>
        </template>
      </van-swipe-item>
      <van-swipe-item class="box-item box2">
        <template v-if="swipeIndex == 1">
          <van-image class="box-item-bg" :src="images.bg[1]" />
          <div class="box-item-content">
            <div class="box2-iphone">
              <van-image class="box2-iphone-item" :src="images.iphone[2]" />
              <div class="box2-iphone-item">
                <van-image width="100%" height="100%" :src="images.iphone[3]" />
                <van-image class="box2-heart" :src="images.heart" />
              </div>
            </div>
            <van-image class="box2-font" :src="images.font[1]" />
            <van-image class="box2-girl" :src="images.girl[1]" />
            <van-image  class="box-slide" :src="images.slide_moible[1]" />
            <van-image  class="box-slide" :src="images.slide_pc[1]" />
          </div>
        </template>
      </van-swipe-item>
      <van-swipe-item class="box-item box3" v-show="swipeIndex == 2">
        <template v-if="swipeIndex == 2">
          <van-image class="box-item-bg" :src="images.bg[2]" />
          <div class="box-item-content">
            <div class="box3-detail">
              <!-- <van-image class="box3-font" :src="images.font[2]" /> -->
              <br />
              <!-- <p class="box3-detail-title">
                生活中找不到一个异性朋友聊天？来平台，里面都是真人实名认证的陌生人，你可以通过文字，语音，视频方式随心所欲的畅聊，聊得好的还可以线下见一面，说不定TA就是你生命中的另一半。
              </p>
              <br />
              <p>主要功能：</p>
              <p>【速配交友】真人在线匹配，缘分速配对的TA，实时快速畅聊，交友更贴心。</p>
              <p>【真人认证】每一位用户都经过系统和人工双重的审核，让聊天交友有保障。</p>
              <p>【实名审核】个人资料都经过实名审核，避免弄虚作假，打造更真实的交友。</p>
              <p>【互动聊天】文字，语音，视频等互动方式聊天，随时随地都可以谈天说地。</p>
              <br />
              <p>茫茫人海里，注定有一个属于你的Ta在等你，青春不老勇敢爱!</p>

              <br />
              <br />
              <p class="notice">
                本网站目前暂不开展游戏经营，后期网站开展游戏经营再变更备案和提供版号
              </p> -->
            </div>
            <van-image v-if="images.about" class="box3-about" :src="images.about" />
          </div>
        </template>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import _ from 'lodash';
import { AppInfo } from "@/utils/Host";
export default {
  data() {
    return {
      isMobile: true,
      menu: 0,
      istaliao: false,
      // menuList: ["首页", "充值"],
      menuList: ["首页"],
      swipeIndex: 0,
      m_menu_visible: false,
      images: {
        m_menu: "https://cdn.bzxd.tv/h5/images/index/home/m_menu.png",
        bg: [
        ],
        iphone: [
          `https://cdn.bzxd.tv/h5/images/index/home/iphone_2.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/iphone_1.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/iphone_4.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/iphone_3.png`,
        ],
        font: [
          `https://cdn.bzxd.tv/h5/images/index/home/font_1.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/font_2.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/font_3.png`,
        ],
        girl: [
          `https://cdn.bzxd.tv/h5/images/index/home/girl_1.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/girl_2.png`,
        ],
        down: `https://cdn.bzxd.tv/h5/images/index/home/down.png`,
        heart: `https://cdn.bzxd.tv/h5/images/index/home/heart.png`,
        // about: this.$t(`${URL}.about`),
        slide_moible: [
          `https://cdn.bzxd.tv/h5/images/index/home/slide_moible_1.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/slide_moible_2.png`,
        ],
        slide_pc: [
          `https://cdn.bzxd.tv/h5/images/index/home/slide_pc_1.png`,
          `https://cdn.bzxd.tv/h5/images/index/home/slide_pc_2.png`,
        ],
      },
    };
  },
  computed: {
    isShowNav() {
      // if (this.isMobile) {
      //   return false;
      // }
      if (this.menu != 0) {
        return true;
      }
      if (this.swipeIndex != 0) {
        return false;
      }
      return true;
    },
  },
  created() {
    let { hostname } = window.location;
    if (hostname == "www.bzxd.tv") {
      this.istaliao = true;
    }
  },
  mounted() {
  //   const handleScrollDebounced = _.debounce(this.handleScroll, 500);
  // window.addEventListener('scroll', handleScrollDebounced);

    window.addEventListener("mousewheel", _.debounce(this.handleScroll));
    this.images.bg = [
          AppInfo.imgage,
          `https://cdn.bzxd.tv/h5/images/index/home/bg2.png`,
          AppInfo.imgage_1,
        ]
  },
  methods: {
    handleScroll(e) {
      this.$nextTick(() => {
        let dir = e.deltaY; // 正值为向下滚动，负值为向上滚动
        console.log(dir); // 正值为向下滚动，负值为向上滚动
        try {
          if (dir > 0) {
            this.$refs.vSwipe.next();
          } else {
            this.$refs.vSwipe.prev();
          }
        }
         catch(error){ //
        }
      });
    },
    onChangeSwipe(i) {
      console.log("onChangeSwipe:", i);
      this.swipeIndex = i;
    },
    onMenuClick(index = 0) {
      if (index == 1) {
        this.toPay();
        return;
      }
      this.menu = index;
    },
    toPay() {
      this.$router.push({ path: "webPay" });
    },
    onDownLoad() {
      this.$refs.downLoadApp.downLoadApp();
    },
  },
  beforeDestroy() {
    
    window.removeEventListener("mousewheel", _.debounce(this.handleScroll));
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/animate";
@import "./styles/pc";
@import "./styles/mobile";
</style>
